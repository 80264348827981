/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Can } from 'src/utils/CaslContext';
import { subject } from '@casl/ability';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import getInitials from 'src/utils/getInitials';
import _startCase from 'lodash/startCase';

const navConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'Push Notifications',
        href: '/push-notifications',
        icon: NotificationsNoneOutlinedIcon
      },
      {
        title: 'Colleges',
        icon: SchoolOutlinedIcon,
        href: '/colleges/admissions',
        items: [
          {
            title: 'Admissions List',
            href: '/colleges/admissions'
          }
        ]
      },
      {
        title: 'Roles & Permissions',
        href: '/roles-permissions',
        icon: LockOutlinedIcon
      },
      {
        title: 'Cache Management',
        href: '/cache-management',
        icon: CachedOutlinedIcon
      },
      {
        title: 'Ad Management',
        href: '/ad-management',
        icon: PublicIcon
      },
      {
        title: 'Elements',
        href: '/elements',
        icon: DonutLargeIcon
      },
      {
        title: 'Media Library',
        href: '/media-library',
        icon: PermMediaIcon
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    acc.push(
      <Can I="manage" key={key} a={subject('Route', { route: item.href })}>
        <NavItem
          depth={depth}
          icon={item.icon}
          key={key}
          info={item.info}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      </Can>
    );
  } else {
    acc.push(
      <Can I="manage" key={key} a={subject('Route', { route: item.href })}>
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={key}
          info={item.info}
          title={item.title}
        />
      </Can>
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: colors.red[500],
    fontSize: '1.8rem'
  }
}));

function NavBar({ data, openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  // const { user } = useSelector(state => state.account);
  const { first_name, last_name, role, profile_image } = data || {};
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <Link to="/" component={RouterLink}>
              <Logo />
            </Link>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Link to="/profile" underline="none" component={RouterLink}>
              <Avatar
                alt="User"
                className={classes.avatar}
                src={
                  profile_image
                    ? `${process.env.REACT_APP_IMAGE_BASE_URL}${profile_image}`
                    : ''
                }
              >
                {getInitials(first_name)}
              </Avatar>
            </Link>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/profile"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {first_name ? `${first_name} ${last_name || '-'}` : '-'}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {_startCase(role)}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map(config => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        {/*<Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box>*/}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
