import api from 'src/utils/api';
export const value = {
  dedupingInterval: 10000,
  shouldRetryOnError: false,
  fetcher: async url => {
    try {
      const res = await api.get(url);
      return res;
    } catch (error) {
      throw error;
    }
  }
};
