/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment, useContext } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import Error404View from 'src/views/pages/Error404View';
import { UserContext } from 'src/context/UserContext';
import { Can } from 'src/utils/CaslContext';
import { subject } from '@casl/ability';
import CustomProgress from 'src/components/CustomProgress';
const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/push-notifications" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },

  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        guard: AuthGuard,
        component: () => <Redirect to="/profile" />
      },
      {
        exact: true,
        path: '/media-library',
        guard: AuthGuard,
        component: lazy(() => import('src/views/MediaLibrary'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/elements',
        component: lazy(() => import('src/views/Elements'))
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/push-notifications/create',
        component: lazy(() => import('src/views/PushNotifications'))
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/push-notifications/:id/edit',
        component: lazy(() =>
          import('src/views/PushNotifications/NotificationEdit')
        )
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/roles-permissions',
        component: lazy(() => import('src/views/RolesAndPermissions'))
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/push-notifications',
        component: lazy(() => import('src/views/AllNotifications'))
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/ad-management',
        component: lazy(() => import('src/views/adManagement'))
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/profile',
        component: lazy(() => import('src/views/UserProfile'))
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/colleges/admissions',
        component: lazy(() => import('src/views/Admissions/AdmissionList'))
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/colleges/admissions/create',
        component: lazy(() => import('src/views/Admissions/CreateAdmission'))
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/colleges/admissions/:id',
        component: lazy(() => import('src/views/Admissions/ViewAdmission'))
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/colleges/admissions/edit/:id',
        component: lazy(() => import('src/views/Admissions/CreateAdmission'))
      },
      {
        guard: AuthGuard,
        exact: true,
        path: '/cache-management',
        component: lazy(() => import('src/views/CacheManagement'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes, isAuthenticated) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const path = route.path;

          return (
            <Route
              key={i}
              path={path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Can
                        I="manage"
                        a={subject('Route', { route: path })}
                        passThrough
                      >
                        {allowed =>
                          allowed ? <Component {...props} /> : <Error404View />
                        }
                      </Can>
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  const { loading, isAuthenticated } = useContext(UserContext);
  if (loading) {
    return <CustomProgress />;
  } else return renderRoutes(routesConfig, isAuthenticated);
}

export default Routes;
