/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment, useContext } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import Error404View from 'src/views/pages/Error404View';
import { UserContext } from 'src/context/UserContext';
import { Can } from 'src/utils/CaslContext';
import { subject } from '@casl/ability';
import CustomProgress from 'src/components/CustomProgress';
const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/auth/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,
    path: '/auth/reset-password',
    component: lazy(() => import('src/views/auth/ResetPassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,
    path: '/auth/create-password',
    component: lazy(() => import('src/views/auth/CreatePassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,
    path: '/auth/forget-password',
    component: lazy(() => import('src/views/auth/ForgotPassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,
    path: '/auth/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },

  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,
    path: '/auth/signup',
    component: lazy(() => import('src/views/auth/RegisterView'))
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const path = route.path;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    <Component {...props} />
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function AuthRoutes() {
  const { loading } = useContext(UserContext);
  if (loading) {
    return <CustomProgress />;
  } else return renderRoutes(routesConfig);
}

export default AuthRoutes;
