import * as firebase from 'firebase/app';
import 'firebase/messaging';
const firebaseConfig =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? {
        apiKey: 'AIzaSyBFC4rW0KAxWTuu3dxSzM0D6awbI55YGT8',
        projectId: 'dashboard-prod-263cc',
        messagingSenderId: '947949411003',
        appId: '1:947949411003:web:8eab4e655beaa4585a279e'
      }
    : {
        // Project Settings => Add Firebase to your web app
        apiKey: 'AIzaSyCq_neRR13MSb-WZgP9ZNT2LrugECKZ2vQ',
        projectId: 'ezcf-beta-push-notification',
        messagingSenderId: '503369425239',
        appId: '1:503369425239:web:9230ae0294d079011bde12'
      };
const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
let messaging;
if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
}

export { messaging };
