export default {
  auth: {
    signIn: '/api/v1/auth/login',
    signUp: '/api/v1/auth/signup',
    generatePassword: '/api/v1/auth/signup/password-create',
    forgotPassword: '/api/v1/auth/forgot-password',
    resetPassword: '/api/v1/auth/forgot-password/reset'
  },
  user: 'api/v1/user',
  subscribe: 'api/v1/user/subscribe',
  pushNotification: 'api/v1/push-notification',
  topics: 'api/v1/push-notification/topics',
  allNotificationFilters: 'api/v1/push-notification/filters',
  userNotifications: 'api/v1/user/notifications',
  permission: 'api/v1/permission',
  role: 'api/v1/permission/role',
  admission: {
    base: 'api/v1/colleges/admissions',
    search: 'api/v1/colleges/admissions/search',
    view_admission: '/api/v1/colleges/admissions/',
    filters: '/api/v1/colleges/admissions/filters'
  },
  cacheManagement: '/api/v1/cache-management'

  // logout: '/api/v1/user/logout',
  // overview: 'api/v1/overview',
  // lead: 'api/v1/lead'
  //   application: 'api/v1/application',
  //   assignedLeads: '/api/v1/lead',
  //   cafLeads: '/api/v1/source?source=caf',
  //   micrositeLeads: 'microsite',
  //   ezapLeads: '/api/v1/source?source=ezap',
  //   distanceLeads: 'distance',
  //   takeAction: '/api/v1/lead',
  //   addReminder: '/api/v1/lead/reminder',
  //   counsellorActivity: '/api/v1/counsellor',
  //   fetchStatusAndActivity: '/api/v1/lead/activity-meta',
  //   counsellors: 'api/v1/counsellor',
  //   colleges: 'api/v1/college',
  //   leads: 'api/v1/lead',
  //   source: '/api/v1/source',
  //   panel: 'api/v1/panel'
};
