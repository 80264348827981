import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Logo"
      src="/static/ezone.svg"
      style={{ marginTop: '0.5rem' }}
      {...props}
    />
  );
}

export default Logo;
