import React, { useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import { UserContext } from '../../../context/UserContext';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: colors.red[500],
    color: colors.common.white,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Account({ url, name }) {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const { setIsAuthenticated } = useContext(UserContext);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      localStorage.removeItem('token');
      setIsAuthenticated(false);
      history.push('/auth/login');
      enqueueSnackbar('Successfully Logged Out');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={url}>
          {getInitials(name)}
        </Avatar>
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          onClick={() => {
            history.push('/profile');
            handleClose();
          }}
        >
          Profile
        </MenuItem>

        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
