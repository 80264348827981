import React, { useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from '../context/UserContext';

function AuthGuard({ children }) {
  const { location: { pathname = '' } = {} } = useHistory();
  const pathnameToRedirect =
    window?.location?.pathname + window?.location?.search ?? pathname ?? '';
  const { isAuthenticated } = useContext(UserContext);

  if (!isAuthenticated) {
    const redirectTo =
      pathname === '/auth/reset-password' ||
      pathname === '/auth/create-password' ||
      pathname === '/auth/forget-password' ||
      pathname === '/auth/signup'
        ? pathnameToRedirect
        : '/auth/login';
    return <Redirect to={redirectTo} />;
  }
  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
