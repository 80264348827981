import React, { createContext, useState, useEffect } from 'react';
import decode from 'jwt-decode';
import { setHeader } from 'src/utils/api';
import api from 'src/utils/api';
import apiEndpoint from 'src/utils/apiConstants';

export const UserContext = createContext();

export const UserProvider = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [overviewFilter, setOverviewFilter] = useState({});
  const [userData, setUserData] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [pushData, setPushData] = useState({});
  const checkAuth = () => {
    const token = localStorage.getItem('token');
    setHeader(token);
    if (!token) {
      return false;
    }
    try {
      const { exp } = decode(token);

      if (exp < new Date().getTime() / 1000) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  };
  const getUserData = async () => {
    try {
      const res = await api.get(apiEndpoint.user);
      setUserData(res?.data || {});
      setNotifications(res?.data?.notifications || []);
    } catch (error) {
      //
    }
  };
  const getUserAuth = async () => {
    setLoading(true);
    const status = checkAuth();
    if (status) {
      await getUserData();
    }
    setIsAuthenticated(status);
    setLoading(false);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      getUserAuth();
    }
  }, []);
  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        notifications,
        setNotifications,
        getUserAuth,
        getUserData,
        setLoading,
        setPushData,
        pushData,
        userData,
        loading,
        overviewFilter,
        setOverviewFilter
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
