import React, { useRef, useState, useContext, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import api from 'src/utils/api';
import moment from 'moment';
import apiEndpoint from 'src/utils/apiConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Badge,
  SvgIcon,
  colors,
  Grid,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon
} from 'react-feather';
import _cloneDeep from 'lodash/cloneDeep';
// import { getNotifications } from 'src/actions/notificationsActions';
import { UserContext } from 'src/context/UserContext';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles(theme => ({
  popover: {
    width: 350
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  list: {
    maxHeight: '18rem',
    overflowY: 'scroll'
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: 'minmax(2rem,min-content) 1fr',
    cursor: 'pointer'
  }
}));

function Notifications() {
  const classes = useStyles();
  let history = useHistory();
  const { notifications, setNotifications, getUserData } = useContext(
    UserContext
  );

  // const [unReadNotifications, setUnReadNotifications] = useState([]);
  // const notifications = useSelector((state) => state.notifications.notifications);
  const ref = useRef(null);
  // const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const maxAttempts = 2;
  let count = 0;
  const markAllReadApi = async () => {
    try {
      if (notifications && notifications.find(item => !item.is_read)) {
        let tempNotifications = _cloneDeep(notifications);
        tempNotifications.forEach(element => {
          element.is_read = true;
        });
        setNotifications(tempNotifications);
        // allRead();
        const res = await api.post(apiEndpoint.userNotifications);
        getUserData();
      }
    } catch (error) {
      //error handling
      if (count < maxAttempts) {
        markAllReadApi();
        count++;
      }
    }
  };
  const markSingleNotificationAsRead = async temp => {
    let tempNotifications = _cloneDeep(notifications);
    tempNotifications.forEach(element => {
      if (element?.id === temp?.id) {
        element.is_read = true;
      }
    });
    setNotifications(tempNotifications);
    if (!temp?.is_read) {
      try {
        await api.post(`${apiEndpoint.userNotifications}/${temp?.id}`);
        getUserData();
      } catch (error) {}
    }
    const tempUrl = new URL(temp?.url);
    if (tempUrl.hostname === window?.location?.host) {
      history.push(tempUrl.pathname);
    } else {
      window.open(temp?.url);
    }
  };
  const unReadNotifications = notifications.filter(item => !item.is_read);
  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <Badge
            badgeContent={unReadNotifications.length}
            classes={{ badge: classes.notificationsBadge }}
          >
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            Notifications
          </Typography>
        </Box>
        {notifications?.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {notifications?.map(notification => {
                const Icon = iconsMap['order_placed'];
                return (
                  <ListItem
                    className={classes.listItem}
                    onClick={() => markSingleNotificationAsRead(notification)}
                    divider
                    key={notification.id}
                    style={
                      notification?.is_read
                        ? {}
                        : { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                    }
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className="listItemText"
                      primary={notification.title}
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: 'textSecondary'
                      }}
                      secondary={notification.description}
                    />
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-end"
                      style={{ gridColumn: '2/-1' }}
                    >
                      <Grid item>
                        {!notification.is_read ? (
                          <Badge
                            classes={{ badge: classes.notificationsBadge }}
                            variant="dot"
                          />
                        ) : null}
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          className="notification-time"
                        >
                          {moment(notification.created_at).fromNow()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button onClick={() => markAllReadApi()} size="small">
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
}

export default Notifications;
