import React, { useEffect, useContext } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import _isEmpty from 'lodash/isEmpty';
import { SWRConfig } from 'swr';
import { ToastContainer } from 'react-toastify';
import { UserContext } from 'src/context/UserContext';
import CustomProgress from 'src/components/CustomProgress';
import firebase from 'firebase/app';
import pushNotificationPermission from './utils/pushNotification';
import { Ability } from '@casl/ability';
import { AbilityContext } from 'src/utils/CaslContext';
import AuthRoutes from './AuthRoutes';
import { useStyles } from 'src/styles/App';
import { value } from 'src/utils/swrConfig';
import 'react-toastify/dist/ReactToastify.css';
// import CookiesNotification from 'src/components/CookiesNotification';
// import SettingsNotification from 'src/components/SettingsNotification';
// import { messaging } from './init-fcm';
// import { UserProvider } from './context/UserContext';
// import api from './utils/api';
// import apiEndpoint from './utils/apiConstants';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true
};
function App() {
  useStyles();
  const { settings } = useSettings();
  const { loading, isAuthenticated, userData } = useContext(UserContext);
  useEffect(() => {
    if (firebase.messaging.isSupported() && isAuthenticated) {
      pushNotificationPermission();
    }
  }, [isAuthenticated]);

  if (loading) {
    return <CustomProgress />;
  }
  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            maxSnack={3}
            variant="success"
            preventDuplicate
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <GoogleAnalytics />
                {/*<CookiesNotification />
                <SettingsNotification />*/}
                <AuthRoutes />
                <AbilityContext.Provider
                  value={new Ability(permissions[userData?.role])}
                >
                  <SWRConfig value={value}>
                    <Routes />
                  </SWRConfig>
                </AbilityContext.Provider>
              </Auth>
            </Router>
            <ToastContainer {...toastSettings} />
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;

const permissions = {
  author: [
    {
      action: 'create',
      subject: 'PushNotification'
    },
    {
      action: 'edit',
      subject: 'PushNotification',
      conditions: { is_published: false }
    },
    {
      action: 'edit',
      subject: 'PushNotification',
      conditions: {
        status: { $in: ['Published', 'Approved', 'Rejected'] }
      },
      inverted: true
    },
    {
      action: 'manage',
      subject: 'Route',
      conditions: {
        route: {
          $in: [
            '/push-notifications',
            '/push-notifications/create',
            '/push-notifications/:id/edit',
            '/profile'
          ]
        }
      }
    }
  ],
  editor: [
    {
      action: 'create',
      subject: 'PushNotification'
    },
    {
      action: 'edit',
      subject: 'PushNotification',
      conditions: { is_published: false }
    },
    {
      action: 'edit',
      subject: 'PushNotification',
      conditions: {
        status: { $in: ['Published', 'Approved', 'Rejected'] }
      },
      inverted: true
    },
    {
      action: 'publish',
      subject: 'PushNotification',
      conditions: { is_published: false }
    },
    {
      action: 'manage',
      subject: 'Route',
      conditions: {
        route: {
          $in: [
            '/push-notifications',
            '/push-notifications/create',
            '/push-notifications/:id/edit',
            '/profile',
            '/cache-management'
          ]
        }
      }
    }
  ],
  admin: [
    {
      action: 'create',
      subject: 'PushNotification'
    },
    {
      action: 'edit',
      subject: 'PushNotification',
      conditions: { is_published: false }
    },
    {
      action: 'edit',
      subject: 'PushNotification',
      conditions: {
        status: { $in: ['Published', 'Approved', 'Rejected'] }
      },
      inverted: true
    },
    {
      action: 'publish',
      subject: 'PushNotification',
      conditions: { is_published: false }
    },
    {
      action: ['create', 'publish'],
      subject: 'AdmissionForm'
    },
    {
      action: 'edit',
      subject: 'AdmissionForm',
      conditions: { is_published: true }
    },
    {
      action: 'manage',
      subject: 'Route',
      conditions: {
        route: {
          $in: [
            '/push-notifications',
            '/push-notifications/create',
            '/push-notifications/:id/edit',
            '/profile',
            '/colleges/admissions',
            '/colleges/admissions/create',
            '/colleges/admissions/edit/:id',
            '/colleges/admissions/:id',
            '/cache-management'
          ]
        }
      }
    }
  ],
  super_admin: [
    {
      action: 'create',
      subject: 'PushNotification'
    },
    {
      action: 'edit',
      subject: 'PushNotification',
      conditions: { is_published: false }
    },
    {
      action: 'edit',
      subject: 'PushNotification',
      conditions: {
        status: { $in: ['Published', 'Approved', 'Rejected'] }
      },
      inverted: true
    },
    {
      action: 'publish',
      subject: 'PushNotification',
      conditions: { is_published: false }
    },
    {
      action: ['create', 'publish'],
      subject: 'AdmissionForm'
    },
    {
      action: 'edit',
      subject: 'AdmissionForm',
      conditions: { is_published: true }
    },
    {
      action: 'manage',
      subject: 'Route',
      conditions: {
        route: {
          $in: [
            '/push-notifications',
            '/push-notifications/create',
            '/push-notifications/:id/edit',
            '/profile',
            '/colleges/admissions',
            '/colleges/admissions/create',
            '/colleges/admissions/edit/:id',
            '/colleges/admissions/:id',
            '/roles-permissions',
            '/cache-management'
          ]
        }
      }
    }
  ]
};
